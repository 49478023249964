export { ReactComponent as AlertIcon } from './Alert.svg';
export { ReactComponent as ExportIcon } from './Export.svg';
export { ReactComponent as SearchIcon } from './Search.svg';
export { ReactComponent as SearchX2Icon } from './Search@x2.svg';
export { ReactComponent as PhotoIcon } from './Photo.svg';
export { ReactComponent as EditCircleIcon } from './EditCircle.svg';
export { ReactComponent as LetterIcon } from './Letter.svg';
export { ReactComponent as GlobeIcon } from './Globe.svg';
export { ReactComponent as PhoneIcon } from './Phone.svg';
export { ReactComponent as EducationIcon } from './Education.svg';
export { ReactComponent as FeedIcon } from './Feed.svg';
export { ReactComponent as BuildingIcon } from './Building.svg';
export { ReactComponent as InvoiceIcon } from './Invoice.svg';
export { ReactComponent as PlaceIcon } from './Place.svg';
export { ReactComponent as ResumeIcon } from './Resume.svg';
export { ReactComponent as UserIcon } from './User.svg';
export { ReactComponent as LogoIcon } from './Logo.svg';
export { ReactComponent as CaretDownIcon } from './CaretDown.svg';
export { ReactComponent as CaretUpIcon } from './CaretUp.svg';
export { ReactComponent as CaretLeftIcon } from './CaretLeft.svg';
export { ReactComponent as CaretRightIcon } from './CaretRight.svg';
export { ReactComponent as CircleIcon } from './Circle.svg';
export { ReactComponent as EditNotBackgroundIcon } from './EditNotBackground.svg';
export { ReactComponent as DisableIcon } from './Disable.svg';
export { ReactComponent as CalendarGroupIcon } from './CalendarGroup.svg';
export { ReactComponent as ViewIcon } from './View.svg';
export { ReactComponent as CreditIcon } from './Credit.svg';
export { ReactComponent as EditIcon } from './Edit.svg';
export { ReactComponent as BackIcon } from './Back.svg';
export { ReactComponent as MessagesIcon } from './Messages.svg';
export { ReactComponent as EnableIcon } from './Enable.svg';
export { ReactComponent as BillingIcon } from './Billing.svg';
export { ReactComponent as FolderIcon } from './Folder.svg';
export { ReactComponent as PaymentIcon } from './Payment.svg';
export { ReactComponent as TextLogoIcon } from './TextLogo.svg';
export { ReactComponent as DropdownIcon } from './Dropdown.svg';
export { ReactComponent as LeftOutlinedIcon } from './LeftOutlined.svg';
export { ReactComponent as RightOutlinedIcon } from './RightOutlined.svg';
export { ReactComponent as StartIcon } from './Start.svg';
export { ReactComponent as CloseIcon } from './Close.svg';
export { ReactComponent as MenuDashIcon } from './MenuDash.svg';
export { ReactComponent as MenuDashCloseIcon } from './MenuDashClose.svg';
export { ReactComponent as TriangleIcon } from './Triangle.svg';
export { ReactComponent as DownOutlinedIcon } from './DownOutlined.svg';
export { ReactComponent as FeedbackIcon } from './Feedback.svg';
export { ReactComponent as ArrowLeftIcon } from './ArrowLeft.svg';
export { ReactComponent as ArrowRightIcon } from './ArrowRight.svg';
export { ReactComponent as AccountSettingsIcon } from './account.svg';
export { ReactComponent as FilterIcon } from './Filter.svg';
export { ReactComponent as CloseViewIcon } from './CloseView.svg';
export { ReactComponent as DownloadIcon } from './Download.svg';
export { ReactComponent as CopyIcon } from './Copy.svg';
export { ReactComponent as UploadIcon } from './UploadIcon.svg';
export { ReactComponent as ExternalLinkIcon } from './ExternalLink.svg';
export { ReactComponent as WriteIcon } from './Write.svg';
export { ReactComponent as InformationIcon } from './information.svg';
export { ReactComponent as UserPermissionIcon } from './UserPermission.svg';
export { ReactComponent as ConfigurationIcon } from './Configuration.svg';
export { ReactComponent as TooltipIcon } from './Tooltip.svg';
export { ReactComponent as ArrowDownIcon } from './ArrowDown.svg';
export { ReactComponent as RemoveIcon } from './RemoveIcon.svg';
export { ReactComponent as TickIcon } from './tick.svg';
export { ReactComponent as URLIcon } from './url.svg';
export { ReactComponent as DeleteIcon } from './Delete.svg';
export { ReactComponent as ViewOutlineIcon } from './EyeIcon.svg';
export { ReactComponent as JodFbAmazingAttitude } from './Jod-PresetFeedback-B2B-AmazingAttitude.svg';
export { ReactComponent as JodFbFastLearner } from './Jod-PresetFeedback-B2B-FastLearner.svg';
export { ReactComponent as JodFbGoodWorkEthic } from './Jod-PresetFeedback-B2B-GoodWorkEthic.svg';
export { ReactComponent as JodFbHighlyDependable } from './Jod-PresetFeedback-B2B-HighlyDependable.svg';
export { ReactComponent as JodFbProficientSkills } from './Jod-PresetFeedback-B2B-ProficientSkills.svg';
export { ReactComponent as JodFbClearCommunication } from './Jod-PresetFeedback-B2C-ClearCommunication.svg';
export { ReactComponent as JodFbGoodTeamwork } from './Jod-PresetFeedback-B2C-GoodTeamwork.svg';
export { ReactComponent as JodFbProfessionalManager } from './Jod-PresetFeedback-B2C-ProfessionalManager.svg';
export { ReactComponent as JodFbSafeWorkplace } from './Jod-PresetFeedback-B2C-SafeWorkplace.svg';
export { ReactComponent as JodFbSufficientBreaks } from './Jod-PresetFeedback-B2C-SufficientBreaks.svg';
export { ReactComponent as JodTextWhite } from './JodTextWhite.svg';
export { ReactComponent as BackIconStrokeLeft } from './BackIconStrokeLeft.svg';
export { ReactComponent as Email } from './Email.svg';
export { ReactComponent as SmsIcon } from './SmsIcon.svg';
export { ReactComponent as AuthenticatorIcon } from './AuthenticatorIcon.svg';
export const ArrowTopRightHoverMob =
  'https://website-assets-portal-v2-frontend-global.s3.ap-southeast-1.amazonaws.com/icons/ArrowTopRightHoverMobile.svg';
export const ArrowTopRightHover =
  'https://website-assets-portal-v2-frontend-global.s3.ap-southeast-1.amazonaws.com/icons/ArrowTopRightHover.svg';
export const ArrowTopRight =
  'https://website-assets-portal-v2-frontend-global.s3.ap-southeast-1.amazonaws.com/icons/ArrowTopRight.svg';
export const ArrowTopRightMob =
  'https://website-assets-portal-v2-frontend-global.s3.ap-southeast-1.amazonaws.com/icons/ArrowTopRightMob.svg';
